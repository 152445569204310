import { merge } from '@/utils/merge';
import { tw } from '@/utils/tw';
import { default as BaseTagTheme } from 'base/components/Tag/theme';

const Tag = tw.theme({
  extend: BaseTagTheme,
  base: 'uppercase',
  variants: merge(BaseTagTheme.variants, {
    colors: {
      default: 'ring-secondary-200 bg-gray-100 text-gray-800 hover:text-gray-800',
      alternative: 'text-gray-700',
    },
    size: {
      small: 'px-3 pb-1 pt-2',
      video: 'mb-2',
    },
  }),
});

export default Tag;
